import { ProjectBackground } from "../../components/ProjectBackground";
import { ProjectHeader } from "../../components/ProjectHeader";
import { ProjectLayout } from "../../components/ProjectLayout";
import { ProjectSection } from "../../components/ProjectSection";

//images
// import recallHero from "../../assets/recallHeo.webm";
import recallHero from "../../assets/recallHero.png";
import stateHero from "../../assets/state_hero.png";
import cat1 from "../../assets/recall_cat12.png";
import cat2 from "../../assets/recall_cat1.png";
import cat3 from "../../assets/recall_cat2.png";
import legacy1 from "../../assets/recall_Group_4117.png";
import legacy3 from "../../assets/recall_Heuristics_Key.png";
import legacy4 from "../../assets/recall_-_Original_-_Audit.png";
import legacy5 from "../../assets/recall_Warranty_-_Original_-_Audit.png";
import cat4 from "../../assets/recall_cat3.png";
import cat5 from "../../assets/recall_cat4.png";
import cat6 from "../../assets/recall_cat5.png";
import phase1_a from "../../assets/recall_phase1_a.png";
// import phase1_2 from "../../assets/recall_Frame_4135.png";
import phase1_b from "../../assets/recall_phase1_b.png";
// import phase2_2 from "../../assets/recall_warranty.png";
// import phase2_3 from "../../assets/recall_-_modal.png";
import cat7 from "../../assets/recall_cat6.png";
import cat8 from "../../assets/recall_cat7.png";
import cat9 from "../../assets/recall_cat8.png";
import cat10 from "../../assets/recall_cat9.png";
import comp1 from "../../assets/recall_comp1.png";
import comp2 from "../../assets/recall_comp2.png";
import cat11 from "../../assets/recall_cat10.png";
import cat12 from "../../assets/recall_cat11.png";
import lowfidelity from "../../assets/recall_low_fidelity.gif";

import { Icon } from "../../components/Icon";
import { Caption } from "../../components/Caption";
import { Column } from "../../components/Column";
import { Content } from "../../components/Content";
// import { CursorClickIcon } from '@heroicons/react/solid';
import { CursorClickIcon, MapIcon, SearchIcon, DatabaseIcon, LockClosedIcon, ClockIcon, ZoomOutIcon, ExclamationIcon, CodeIcon, UserGroupIcon, TrendingUpIcon, CheckCircleIcon, ScaleIcon } from '@heroicons/react/outline';

export const Recall = () => {
    return (
        <ProjectLayout>
            <ProjectHeader
                img={recallHero}
                title="Ensuring Clarity When it Matters"
                subtitle="Ownership Safety - Web Platform"
            />
            <ProjectBackground
                // title="Overview"
                context="At General Motors, I led a redesign of a consumer-facing recall and warranty experience that needed to accommodate authenticated users and guests."
                role="I was heavily involved in design and strategy, worked closely with product owners and engineers, and reframed the problem to resolve the root problem."
            />

            {/* Background
            <ProjectSection>
                <Content className="md:w-3/6 w-full">
                    <h3>Problem</h3>
                    <p>
                        The recall and warranty pages are frequently visited, and it is crucial to transparently display information. Despite their importance, users encountered difficulties in obtaining information about the next steps for recalls and whether they were covered by warranty.
                    </p>
                </Content>
            </ProjectSection> */}

            <ProjectSection>
                <Content className=" w-full bg-zinc-100 p-6 rounded-md outline-dotted">
                    <Column><h3 className="">Outcome Preview</h3>
                        <p>
                            The consolidated experience:
                        </p>
                        <ul>
                            <li>Launched on time across all 4 major GM brands</li>
                            <li>Met compliance requirements</li>
                            <li>Resulted in 8% increase in organic visitors</li>
                        </ul>
                    </Column>
                </Content>
                <Content className="md:w-3/4 w-full">
                    <h3>What prompted us to consolidate the recall and warranties experience?</h3>
                    <p className="font-medium underline underline-offset-8">
                        Lack of clarity
                    </p>
                    {/* <div className="grid md:grid-cols-2 grid-cols-1 md:gap-x-12 gap-6 "> */}
                    <p>
                        The recall and warranty pages are among the most important for a vehicle owner after purchasing a vehicle. It’s crucial to display information transparently as they determine what’s wrong with their vehicle.
                    </p>
                    <p>Despite the pages' importance, users encountered difficulties obtaining information about the next steps for recalls and whether they were covered by warranty.</p>
                    {/* </div> */}
                </Content>
                <Content className="md:w-full w-full">
                    <h3>Goals we aimed to achieve</h3>
                    <div className="grid md:gap-x-12 gap-6 md:grid-cols-2 grid-cols-1">
                        <Column>
                            {/* <Icon src={cat3} alt="cat3" /> */}
                            <TrendingUpIcon className="h-6 w-6 text-zinc-800" />
                            <p className="font-medium">Emphasize scalability</p>
                            <p>
                                How might we design to handle data at scale?
                            </p>
                        </Column>
                        <Column>
                            {/* <Icon src={cat1} alt="cat1" /> */}
                            <CursorClickIcon className="h-6 w-6 text-zinc-800" />
                            <p className="font-medium">Increase engagement</p>
                            <p>
                                What can we do to better serve users information?
                            </p>
                        </Column>
                        {/* <Column>
                        <Icon src={cat2} alt="cat2" />
                        <p className="font-medium">Promote Self-Service</p>
                        <p>
                            How can we encourage users
                            to take action when their car has issues?
                        </p>
                    </Column> */}
                    </div>
                </Content>
            </ProjectSection >

            {/* Getting Started */}
            < ProjectSection >
                <Content>
                    <h2 className="">Getting started</h2>
                    <p className="md:w-4/6 w-full">
                        With time constraints and limited resources, I
                        used research methods that maximize what we want to
                        learn while also being cognizant of the business goals.
                    </p>
                </Content>
                <Content>
                    <Content>
                        <h3 className="">What vehicle owners are saying</h3>
                        <p className="md:w-3/4 w-full">
                            Based on our learnings from interviews with existing
                            vehicle owners, we learned the following:
                        </p>
                    </Content>
                    <div className="grid md:gap-x-12 gap-6 md:grid-cols-3 grid-cols-1">
                        <Column>
                            <CursorClickIcon className="h-6 w-6 text-zinc-800" />
                            {/* <CursorOutline className="h-6 w-6" /> */}
                            {/* <Icon src={cat4} alt="cat4" /> */}
                            <p className="font-medium">
                                More of a necessity
                            </p>
                            <p>
                                They visit the pages because they need to address something wrong.
                            </p>
                        </Column>
                        <Column>
                            {/* <Icon src={cat5} alt="cat5" /> */}
                            <MapIcon className="h-6 w-6 text-zinc-800" />
                            <p className="font-medium">
                                Users often misguided
                            </p>
                            <p>
                                They felt lost when determining what next steps are, further driving users to the customer service lines.
                            </p>
                        </Column>
                        <Column>
                            {/* <Icon src={cat6} alt="cat6" /> */}
                            <SearchIcon className="h-6 w-6 text-zinc-800" />
                            <p className="font-medium">
                                Difficulty finding info
                            </p>
                            <p>
                                They have a difficult time scanning the information they’re looking for.
                            </p>
                        </Column>
                    </div>
                </Content>
                {/* <div className="grid md:grid-cols-2 grid-cols-1 md:gap-x-12 gap-6">
                    <Column>
                        <h3 className="">What we had before</h3>
                        <p>
                            Given that there was an existing experience, we
                            spared no time in learning about the following:
                        </p>
                        <ul>
                            <li>Dynamic v.s. static elements</li>
                            <li>Valuable owner information</li>
                            <li>Content's intended audience</li>
                        </ul>
                    </Column>
                    <div>
                        <Caption>Legacy recall & warranty experience</Caption>

                        <img src={legacy1} alt="legacy1" />
                    </div>
                </div> */}
                <Content>
                    <h3 className="">What's not working</h3>
                    <div className="grid md:grid-cols-2 md:grid-flow-row grid-cols-1 md:gap-x-12 gap-6">
                        <Column className="row-span-1 col-span-1">
                            <p>
                                To better understand and empathize with the user
                                pain points, I conducted a heuristic audit using Nielsen Usability Heuristics of
                                the current experience to determine
                                usability, visual, and accessibility issues.
                            </p>
                        </Column>
                        <div className="row-span-1 col-span-1">
                            <p className="font-medium">Audit highlights</p>
                            <ul>
                                <li>Layout hierarchy issues</li>
                                <li>Lots of scrolling</li>
                                <li>Verbose copy</li>
                            </ul>
                        </div>
                        {/* <div className="col-span-1">
                            <Caption>Heuristics Used</Caption>
                            <img src={legacy3} alt="legacy3" />
                        </div> */}
                        <div className="row-span-4 col-span-1">
                            <Caption>
                                Legacy warranty experience
                            </Caption>
                            <img src={legacy5} alt="legacy5" className="shadow rounded" />

                        </div>
                        <div className="row-span-2 col-span-1">
                            <Caption>Legacy recall experience</Caption>
                            <img src={legacy4} alt="legacy4" className="shadow rounded" />
                        </div>
                    </div>
                </Content>
                <Content>
                    <h3 className="">Knowledge-sharing and alignment</h3>
                    <p className="md:w-4/6 w-full">
                        Since this project touched different teams, it was important to take in their perspectives as we develop a solution that meets the needs of the user and the business.
                    </p>
                    <div className="grid md:gap-x-12 gap-6 md:grid-cols-3 grid-cols-1">
                        <Column>
                            <p>
                                Talking with <b>Product</b> folks, they had conveyed that users often expressed a disconnect after purchasing a vehicle.
                            </p>
                        </Column>
                        <Column>
                            <p>
                                Collecting <b>data and analytics</b>, we learned that the pages for this experience drove lower shared lower than anticipated site traffic for the pages.
                            </p>
                        </Column>
                        <Column>
                            <p>
                                <b>Legal</b> made us aware of the required recall information that needs to be shown to the vehicle owner.
                            </p>
                        </Column>
                    </div>
                </Content>
            </ProjectSection >

            {/* Exploration */}
            < ProjectSection >
                <Content>
                    <h2 className="">Exploration</h2>
                    <p className="md:w-4/6 w-full">
                        We pursued a two-phased approach because of data we didn't yet have, and I collaborated closely with the product to align early and often to share progress and receive feedback. These are some early explorations we considered. Ultimately, we went the modal route due to the content hierarchy and ability to scale with more data.
                    </p>
                    <img className="m-auto" src={lowfidelity} alt="low fidelity" />
                </Content>
                <Content>
                    <h3 className="">Design considerations</h3>
                    <div className="grid md:gap-x-12 gap-6 md:grid-cols-2 grid-cols-1">
                        <Column>
                            <DatabaseIcon className="h-6 w-6 text-zinc-800" />
                            <p className="font-medium">Data dependencies</p>
                            <p>
                                What happens if we don’t have the data
                                yet?
                            </p>
                        </Column>
                        <Column>
                            <LockClosedIcon className="h-6 w-6 text-zinc-800" />
                            <p className="font-medium">Authenticated roles</p>
                            <p>
                                How are we treating different roles in the
                                experience we serve?
                            </p>
                        </Column>
                        {/* <Column>
                            <p className="font-medium">Self-Service</p>
                            <p>
                                How might we consider getting users to their
                                next steps?
                            </p>
                        </Column> */}
                    </div>
                </Content>
                <Content>
                    {/* <h3>Low Fidelity</h3> */}
                    <div className="grid md:grid-cols-2 grid-cols-1 md:gap-x-12 gap-6">
                        <div>
                            <Caption>Phase One - Recall Center</Caption>

                            <img src={phase1_a} alt="Phase One, Recall Center" />
                        </div>
                        {/* <div>
                            <img src={phase1_2} alt="phase1_2" />
                            <Caption>Phase One - Recall Modal</Caption>
                        </div> */}
                        <div>
                            <Caption>
                                Phase One - Recall Details
                            </Caption>
                            <img src={phase1_b} alt="Phase One, Recall Details" />

                        </div>
                        {/* <div>
                            <img src={phase2_2} alt="phase2_2" />
                            <img src={phase2_3} alt="phase2_3" />
                            <Caption>
                                Phase Two - Recall & Warranty Modals
                            </Caption>
                        </div> */}
                    </div>
                </Content>
                <Content className="md:w-full w-full">
                    <h3 className="">Challenges along the way</h3>
                    <div className="grid md:gap-x-12 gap-6 md:grid-cols-3 grid-cols-1">
                        {/* <Column>
                            <p className="font-medium">
                                Adapting to New Processes
                            </p>
                            <p>
                                Working with a copywriter and visual designer
                                meant understanding to their process
                            </p>
                        </Column> */}
                        {/* <Icon src={cat7} alt="cat7" /> */}
                        <Column>
                            <ZoomOutIcon className="h-6 w-6 text-zinc-800" />
                            <p className="font-medium">
                                Under-scoping
                            </p>
                            <p>
                                Concerns with timelines often resulted in discussions related to increasing scope to mitigate band-aid solutions.
                            </p>
                        </Column>
                        {/* <Icon src={cat8} alt="cat8" /> */}
                        <Column>
                            <ExclamationIcon className="h-6 w-6 text-zinc-800" />
                            <p className="font-medium">Navigating constraints</p>
                            <p>
                                We needed to adapt to handle temporary scenarios and limits regarding the visual design styling available.
                            </p>
                        </Column>
                        {/* <Icon src={cat9} alt="cat9" /> */}
                        <Column>
                            <ClockIcon className="h-6 w-6 text-zinc-800" />
                            <p className="font-medium">Managing tight deadlines</p>
                            <p>
                                Legal requiring copy to be displayed to the user threw a wrench in our
                                overall approach, but we adapted accordingly.
                            </p>
                        </Column>
                        {/* <Icon src={cat10} alt="cat10" /> */}
                    </div>
                </Content>
            </ProjectSection >

            {/* Where We Landed */}
            < ProjectSection >
                <Content className="w-full">
                    <h2 className="">Where we landed</h2>
                    <p className="md:w-3/6 w-full">
                        After iterating, gathering feedback, adapting to new
                        constraints, and validating through testing, the
                        experience was successfully built and live.
                    </p>
                </Content>
                <div className="grid md:gap-x-12 gap-6 md:grid-cols-2 grid-cols-1">
                    <div>
                        <Caption>
                            Recall & Warranty Center
                        </Caption>
                        <img src={comp1} alt="comp1" className="shadow-lg" />
                    </div>
                    <div>
                        <Caption>Recall Details</Caption>

                        <img src={comp2} alt="comp2" className="shadow-lg" />
                    </div>
                </div>
                <Content>
                    <h3 className="">The impact</h3>
                    <div className="grid md:gap-x-12 gap-6 md:grid-cols-2 grid-cols-1">
                        {/* <Column>
                            <CodeIcon className="h-6 w-6 text-zinc-800" />
                            <p className="font-medium">
                                Increased productivity
                            </p>
                            <p>
                                "This newly consolidated experience has <span className="bg-indigo-100 text-indigo-800 font-medium">made development easier</span> when making changes"
                                - Product Manager
                            </p>
                        </Column> */}
                        <Column>
                            <CursorClickIcon className="h-6 w-6 text-zinc-800" />
                            <p className="font-medium">
                                Less friction for users
                            </p>
                            <p>
                                User sentiment was positive, with many saying they could find information faster and more efficiently.
                            </p>
                            <p>
                                This improvement was reflected in <span className="bg-indigo-100 text-indigo-800 font-medium">an 8%
                                    increase in organic visitors</span> 60 days post-launch.
                            </p>
                            <p>
                                By consolidating the separate experiences, we improved user satisfaction and engagement, contributing to increased site traffic.
                            </p>



                        </Column>
                        <Column>
                            <UserGroupIcon className="h-6 w-6 text-zinc-800" />
                            <p className="font-medium">
                                Satisfied stakeholders
                            </p>
                            <p>
                                A part of the experience that receives millions of yearly visits in the US across Chevrolet, Cadillac, Buick, and GMC <span className="bg-indigo-100 text-indigo-800 font-medium">successfully launched on time</span> to meet compliance.
                            </p>
                        </Column>
                    </div>
                </Content>
                <Content>
                    <h3 className="">My takeaways</h3>
                    <div className="grid md:gap-x-12 gap-6 md:grid-cols-2 grid-cols-1">
                        <Column>
                            {/* <Icon src={cat11} alt="cat11" /> */}
                            <CheckCircleIcon className="h-6 w-6 text-zinc-800" />
                            <p className="font-medium">
                                Safety above all
                            </p>
                            <p>
                                Learning that vehicle owners depend on this info
                                to address vehicle safety issues is something I had not
                                fully understood until I started this project.
                            </p>
                        </Column>
                        <Column>
                            {/* <Icon src={cat12} alt="cat12" /> */}
                            <ScaleIcon className="h-6 w-6 text-zinc-800" />
                            <p className="font-medium">Working with legal</p>
                            <p>
                                Reducing the copy was recommended from a usability perspective, but I
                                experienced initial pushback with legal so I needed to
                                adjust my approach accordingly.
                            </p>
                        </Column>
                    </div>
                </Content>
                <Content className="md:w-4/6 w-full">
                    <h3 className="">Next steps</h3>
                    <p>
                        Next steps included an initiative to integrate more support features as the newly designed experience was created with scalability in mind.
                    </p>
                    {/* TODO: Embed PDFs */}
                </Content>
            </ProjectSection >
        </ProjectLayout >
    );
};
