import { motion } from "framer-motion";
import {
    container,
    introductionFade,
    item,
    itemFade,
} from "../components/Animations";
import sedona from "../assets/sedona.webp";

export default function Introduction() {
    return (
        <motion.div
            variants={introductionFade}
            className="container h-vh flex mx-auto flex-wrap lg:flex-row gap-12 my-24"
        >
            <motion.div
                variants={itemFade}
                className="text-4xl md:basis-6/12 sm:basis-full mb-4 text-zinc-900 font-Syne font-semibold"
            >
                A bit about myself
            </motion.div>
            <motion.div variants={itemFade} className="text-base md:basis-3/4 sm:basis-full grid md:grid-cols-2 gap-x-12 text-zinc-800 font-Overpass font-light">
                <p className="mb-4" > Previously, I've worked in a wide range of industries where I held a diverse set of design roles. In doing so, I’ve developed a love for visual, interaction, and information architecture.</p>
                <p className="mb-4">I also enjoy tinkering with front-end development (like this site you're on) and branding projects for friends.</p>
            </motion.div>
            <motion.div variants={itemFade} className=" container">
                <img
                    src={sedona}
                    className="object-cover object-center rounded-2xl w-full h-72 my-auto shadow"
                    alt="Justin overlooking the majestic landscapes of Sedona National Park."
                ></img>
            </motion.div>
        </motion.div>
    );
}
